import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllPages(ctx, queryParams) {
      let radioId=queryParams.radioId;

      return new Promise((resolve, reject) => {
        axios
          .get('admin/radio/toplist/?radioId='+radioId,{ params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchPost(ctx, queryParams) {
      var id=queryParams.id;

      return new Promise((resolve, reject) => {
        axios
          .get(`admin/cms/blogpost/${id}`,{ params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createDepartment(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/radio/toplist',  userData  )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateblogtag(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put('admin/radio/toplist',  userData  )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteradio(ctx, userData) {
      console.log("deleting: ", userData)
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin/radio/toplist/${userData.id}`,  userData  )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


  },
}
